import Html from 'slate-html-serializer'
import { Editor, getEventTransfer, EventHook } from 'slate-react'
import { Value } from 'slate'
import DeepTable from 'slate-deep-table'
import DropOrPasteImages from 'slate-drop-or-paste-images'
import Lists from '@convertkit/slate-lists'
import React, { useState, useRef, useEffect, FunctionComponent, KeyboardEvent } from 'react'
import {
  IconBold,
  IconItalic,
  IconUnderline,
  IconOrderedList,
  IconUnorderedList,
  // IconStrikethrough,
  IconSuperscript,
  IconSubscript,
  IconTable,
  IconImage,
} from '../icons'
import { RULES } from './serializerRules'
import Strings from '../strings'

const DEFAULT_NODE = 'paragraph'

const SCHEMA = {
  blocks: {
    image: {
      isVoid: true,
    },
  },
}

const serializer = new Html({ rules: RULES, defaultBlock: { type: DEFAULT_NODE } })

type Props = {
  value: string
  name: string
  className?: string
  placeholder?: string
  notFormik?: boolean
  onChange: (name: any, value: string) => void
}

const plugins = [
  // SoftBreak(),
  DeepTable({
    /* options object here; see below */
  }),
  DropOrPasteImages({
    extensions: ['png'],
    insertImage: (change, file) => {
      return change.insertBlock({
        type: 'image',
        isVoid: true,
        data: { file },
      })
    },
  }),
  /* eslint-disable @typescript-eslint/camelcase */
  Lists({
    blocks: {
      ordered_list: 'ordered-list',
      unordered_list: 'unordered-list',
      list_item: 'list-item',
    },
    classNames: {
      ordered_list: 'ordered-list',
      unordered_list: 'unordered-list',
      list_item: 'list-item',
    },
  }),
  /* eslint-enable @typescript-eslint/camelcase */
]

const RichEditor: FunctionComponent<Props> = props => {
  const [value, setValue] = useState(
    props.value ? serializer.deserialize(props.value) : serializer.deserialize(emptyRichText),
  )

  useEffect(() => {
    if (props.value !== serializer.serialize(value)) {
      setValue(props.value ? serializer.deserialize(props.value) : serializer.deserialize(emptyRichText))
      if (isEmptyRichText(props.value)) {
        props.onChange(props.name, props.value)
      }
    }
  }, [props.value])

  const editor = useRef<any>(null)

  const hasBlock = type => value.blocks.some(node => node!.type === type)
  const hasMark = type => value.activeMarks.some(mark => mark!.type === type)

  const renderMarkButton = (type, icon) => {
    return (
      <button
        type="button"
        tabIndex={-1}
        className="flex align-center px=2"
        onMouseDown={event => onClickMark(event, type)}
      >
        {icon}
      </button>
    )
  }

  const renderBlockButton = (type, icon) => {
    if (['ordered-list', 'unordered-list'].includes(type)) {
      const { document, blocks } = value

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key)
      }
    }

    return (
      <button
        type="button"
        tabIndex={-1}
        className="flex align-center px=2"
        onMouseDown={event => onClickBlock(event, type)}
      >
        {icon}
      </button>
    )
  }

  const onChange = change => {
    setValue(change.value)

    if (value.document != change.value.document) {
      props.onChange(props.name, serializer.serialize(change.value))
    }
  }

  const onClickMark = (event, type) => {
    event.preventDefault()
    editor!.current!.toggleMark(type)
  }

  const onClickBlock = (event, type) => {
    event.preventDefault()

    const { current } = editor

    const isActive = hasBlock(type)

    current.setBlocks(isActive ? DEFAULT_NODE : type)
  }

  const renderMark = (props, editor, next) => {
    const { children, mark, attributes } = props

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>
      case 'italic':
        return <em {...attributes}>{children}</em>
      case 'underlined':
        return <u {...attributes}>{children}</u>
      case 'strikethrough':
        return <s {...attributes}>{children}</s>
      case 'superscript':
        return <sup {...attributes}>{children}</sup>
      case 'subscript':
        return <sub {...attributes}>{children}</sub>
      default:
        return next()
    }
  }

  const renderBlock = (props, editor, next) => {
    const { attributes, children, node } = props

    switch (node.type) {
      case 'unordered-list':
        return <ul {...attributes}>{children}</ul>
      case 'ordered-list':
        return <ol {...attributes}>{children}</ol>
      case 'list-item':
        return <li {...attributes}>{children}</li>
      case 'image':
        return <Image {...props} />
      default:
        return next()
    }
  }

  const onPaste = (event, editor, next) => {
    event.preventDefault()
    const transfer = getEventTransfer(event)
    if (transfer.type !== 'html') return next()
    const { document } = serializer.deserialize((transfer as any).html)
    editor.insertFragment(document)
  }

  const insertImage = event => {
    event.preventDefault()
    const { current } = editor
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.addEventListener('load', () =>
      current.insertBlock({
        type: 'image',
        isVoid: true,
        data: {
          file,
          src: reader.result!.toString(),
        },
      }),
    )
    reader.readAsDataURL(file)
  }

  const onInsertTable = () => {
    onChange(editor.current.insertTable())
  }
  const onInsertColumn = () => {
    onChange(editor.current.insertColumn())
  }
  const onInsertRow = () => {
    onChange(editor.current.insertRow())
  }
  const onRemoveColumn = () => {
    onChange(editor.current.removeColumn())
  }
  const onRemoveRow = () => {
    onChange(editor.current.removeRow())
  }
  const onRemoveTable = () => {
    onChange(editor.current.removeTable())
  }
  const onToggleHeaders = () => {
    onChange(editor.current.toggleTableHeaders())
  }

  const renderTableToolbar = () => {
    return (
      <div className="c=sec p=2 flex align-center">
        <button tabIndex={-1} type="button" className="px=2" onClick={onInsertColumn}>
          Spalte hinzufügen
        </button>
        <button tabIndex={-1} type="button" className="px=2" onClick={onInsertRow}>
          Zeile hinzufügen
        </button>
        <button tabIndex={-1} type="button" className="px=2" onClick={onRemoveColumn}>
          Spalte löschen
        </button>
        <button tabIndex={-1} type="button" className="px=2" onClick={onRemoveRow}>
          Zeile löschen
        </button>
        <button tabIndex={-1} type="button" className="px=2" onClick={onRemoveTable}>
          Tabelle löschen
        </button>
        <button tabIndex={-1} type="button" className="px=2" onClick={onToggleHeaders}>
          Kopfzeile
        </button>
      </div>
    )
  }

  const isTable = !!editor.current && editor.current.isSelectionInTable(value)
  const toggleUnorderedList = event => {
    event.preventDefault()
    editor.current.toggleList()
  }

  const toggleOrderedList = event => {
    event.preventDefault()
    editor.current.toggleList({ type: 'ordered-list' })
  }

  return (
    <div className="c=ter richtext w=full">
      <div className="c=sec p=1 flex align-center">
        {renderMarkButton('bold', <IconBold />)}
        {renderMarkButton('underlined', <IconUnderline />)}
        {renderMarkButton('italic', <IconItalic />)}
        {/* {renderMarkButton('strikethrough', <IconStrikethrough />)} */}
        <button type="button" tabIndex={-1} className="flex align-center px=2" onMouseDown={toggleUnorderedList}>
          <IconUnorderedList />
        </button>
        <button type="button" tabIndex={-1} className="flex align-center px=2" onMouseDown={toggleOrderedList}>
          <IconOrderedList />
        </button>
        {renderMarkButton('superscript', <IconSuperscript />)}
        {renderMarkButton('subscript', <IconSubscript />)}
        <label className="pointer flex align-center px=2">
          <IconImage />
          <input type="file" onChange={insertImage} className="hidden" />
        </label>
        <button tabIndex={-1} className="px=2" type="button" onClick={onInsertTable}>
          <IconTable />
        </button>
      </div>
      {isTable && renderTableToolbar()}
      <Editor
        placeholder={props.placeholder}
        className={`p=2 richtext ${props.className}`}
        ref={editor}
        onChange={onChange}
        value={value}
        renderMark={renderMark}
        renderBlock={renderBlock}
        plugins={plugins}
        onPaste={onPaste}
        schema={SCHEMA}
      />
    </div>
  )
}

const Image = props => {
  const { node, attributes } = props
  const { data } = node

  const src = data.get('src')

  return src ? <img {...attributes} src={src} /> : <div {...attributes}>{Strings.de.components.form.loading}...</div>
}

const emptyRichText = serializer.serialize(
  Value.fromJSON({
    document: {
      nodes: [
        {
          object: 'block',
          type: 'paragraph',
          nodes: [
            {
              object: 'text',
              text: '',
            },
          ],
        },
      ],
    },
  }),
)

const isEmptyRichText = value => {
  return value === emptyRichText || value === ''
}

export default RichEditor
export { emptyRichText, isEmptyRichText, serializer }
